import React from 'react'
import Layout from "../templates/Layout"
import { Hero, Container, Row, } from "@edinburghairport/runway"
import '../scss/main.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class RootIndex extends React.Component {

  render() {

    const content = {
      background: {
        file: {
          url: "https://edinburghairport.s3-eu-west-1.amazonaws.com/files/acp2019/home_bg.png"
        }
      },
      heading: "Use of cookies by Edinburgh Airport",
      subtitle: "Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. The table below explains the one essential cookie we use and why, as well as the Google Analytics cookies we’d like to use -  and why.",

    };

    return (
      <Layout>
        <Hero content={content} />

        <div className="section section__cookie-policy">
          <Container>

            <Row>
              <div className="col-sm-12 col-md-3">
                <h3> Cookie</h3>
                <p>Universal Analytics (Google)</p>
              </div>
              <div className="col-sm-12 col-md-3">
                <h3>Name</h3>
                <p>_ga</p>
                <p>_gid</p>
                <p>_gat</p>
              </div>
              <div className="col-sm-12 col-md-6">
                <h3>Purpose</h3>
                <p>These cookies are used to collect information about how visitors use our website. We use the information to compile reports and to help us improve the website. The cookies collect information in an anonymous form, including the number of visitors to the website, where visitors have come to the website from and the pages they visited.</p>
                <p><a href="https://support.google.com/analytics/answer/6004245">Read Google's overview of privacy and safeguarding data</a></p>
              </div>

            </Row>
            <Row>
              <div className="col-sm-12 col-md-3">
              <h3 className="mobile"> Cookie</h3>
                <p>Cookie preference</p>
              </div>
              <div className="col-sm-12 col-md-3">
              <h3 className="mobile">Name</h3>
                <p>cookieconsent_status</p>
              </div>
              <div className="col-sm-12 col-md-6">
              <h3 className="mobile">Purpose</h3>
                <p>This cookie is used to remember a user’s choice about cookies on airspacechange.edinburghairport.com. Where users have previously indicated a preference, that user’s preference will be stored in this cookie.</p>
              </div>
            </Row>

          </Container>
        </div>
      </Layout>
    )
  }
}

export default RootIndex